




















import { InputValidationRule } from 'vuetify';
import { PropType, computed, defineComponent } from '@/plugins/composition';
import { Question, Validation } from '@/models';
import { VSelect, VTextField, VTextarea } from 'vuetify/lib/components';
import { VueConstructor } from 'vue';
import { rules as validations } from '../composables/validations';

export default defineComponent({
  name: 'SurveyQuestion',
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    function resolveComponent(name: string): VueConstructor {
      const componentMap = {
        text: VTextField,
        textarea: VTextarea,
        select: VSelect,
      };

      return componentMap[name as keyof typeof componentMap];
    }

    function getRules(question: Question): InputValidationRule[] {
      const rules = question.data.validations.map((validation: Validation) => {
        const ruleFunction = validations[validation.key as keyof typeof validations];
        if (!ruleFunction) {
          return null;
        }
        return ruleFunction(validation.params ?? '');
      });

      return rules.filter((item): item is InputValidationRule => item !== null);
    }

    return {
      resolveComponent,
      getRules,
      inputValue,
      emit,
    };
  },
});
