























import SurveyQuestion from './SurveyQuestion.vue';
import { AnswerData, Assessment, Patient, Question, Survey } from '@/models';
import { PropType, defineComponent, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';

export default defineComponent({
  components: { SurveyQuestion },
  name: 'SurveyWrapper',
  props: {
    survey: {
      type: Object as PropType<Survey>,
      required: true,
    },
    initialAssessment: {
      type: Object as PropType<Assessment>,
      required: false,
    },
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const questions = ref<Question[]>([]);
    const valid = ref(false);
    const assessment = ref<Assessment>();
    const creating = ref(false);

    const loadQuestions = async () => {
      questions.value = await props.survey.questions().getAll();
    };

    const setAnswer = (event: string, question: Question) => {
      question.tempData.answer = event;
    };

    const submit = debouncedSearch(() => updateAssessment());

    const updateAssessment = async () => {
      if (assessment.value && valid.value) {
        const updateAssessment = await assessment.value.updateRaw({ questions: mapQuestions() }, { message: false });
        mapAnswers(updateAssessment);
      }
    };

    const mapAnswers = (assessment: Assessment) => {
      assessment.data.answers.map((answer: AnswerData) => {
        const question = questions.value.find((question: Question) => question.uuid === answer.question);
        if (question && question.data.calculated) {
          question.tempData.answer = answer.value;
        }
      });
    };

    const mapQuestions = () => {
      return questions.value.map((question: Question) => ({
        uuid: question.data.uuid,
        value: question.tempData.answer,
      }));
    };

    const setAssessment = async () => {
      creating.value = true;
      await loadQuestions();
      assessment.value = await props.patient.assessments().create({
        survey: props.survey.uuid,
        questions: mapQuestions(),
      });
      creating.value = false;
    };

    watch(
      () => props.patient,
      async () => await setAssessment(),
      { immediate: true }
    );

    return {
      questions,
      setAnswer,
      valid,
      creating,
      submit,
      assessment,
      emit,
    };
  },
});
