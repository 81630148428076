





















import PatientSelectInput from '@/components/inputs/PatientSelectInput.vue';
import SurveyQuestion from './SurveyQuestion.vue';
import SurveySelectInput from '@/components/inputs/SurveySelectInput.vue';
import SurveyWrapper from './SurveyWrapper.vue';
import { Patient, Survey } from '@/models';
import { PropType, defineComponent, ref, watch } from '@/plugins/composition';

export default defineComponent({
  components: { SurveyQuestion, SurveyWrapper, PatientSelectInput, SurveySelectInput },
  name: 'CreateAssessment',
  props: {
    initPatient: {
      type: Object as PropType<Patient>,
    },
  },

  setup(props, { emit }) {
    const survey = ref<Survey>();
    const patient = ref<Patient>();

    watch(
      () => props.initPatient,
      (initPatient?: Patient) => {
        if (initPatient) {
          patient.value = initPatient;
        }
      },
      { immediate: true }
    );

    return {
      survey,
      patient,
      emit,
    };
  },
});
