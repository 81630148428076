


















import { Practitioner, Survey } from '@/models';

import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';

export default defineComponent({
  name: 'SurveySelectInput',

  props: {
    value: {
      type: Object as PropType<Survey | null>,
      required: false,
    },
    practitioner: {
      type: Object as PropType<Practitioner | null>,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const surveys = ref<Survey[]>([]);
    const search = ref(null);
    const loading = ref(false);

    const survey = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    const rules = props.required ? [(v: Survey | null): string | boolean => !!v || 'Survey is required'] : [];

    const fetchSurveys = async (): Promise<void> => {
      if (search.value === survey.value?.data.name) {
        return;
      }
      loading.value = true;
      surveys.value = await Survey.where('name', search.value).where('show_in_booking', true).get();
      loading.value = false;
    };

    const searchSurveys = debouncedSearch(() => fetchSurveys());

    watch(search, () => searchSurveys(), { immediate: true });

    return {
      surveys,
      search,
      survey,
      loading,
      rules,
    };
  },
});
